import React from 'react'
import styled from '@emotion/styled'

import { button, colors } from '../styles/theme'

import decorationsSrc from '../img/decorations-on-light.svg'

// import { graphql } from 'gatsby'

import EtchVideo from '../components/etch-video'

import phoneIcon from '../icons/phone.svg'
import meetingIcon from '../icons/meeting.svg'
import tapeMeasureIcon from '../icons/tape-measure.svg'
import dummyIcon from '../icons/dummy.svg'
import clothIcon from '../icons/cloth.svg'
import jacketIcon from '../icons/jacket.svg'
import buttonsIcon from '../icons/buttons.svg'

import Layout from '../components/layout'
import Seo from '../components/SEO'
// import PageHero from '../components/shared/page-hero'
import PageBody from '../components/shared/page-body'

const EtchPage = ({ data }) => (
  <Layout>
    <Seo title="ETCH: Made-to-Measure Suits from just £299" pathname="/etch/" />
    {/* <PageHero
      image={data.hero.childImageSharp}
      title="Refresh your wardrobe with ETCH made-to-measure suits"
    /> */}
    <PageBody>
      <div className="container">
        <h1 className="heading heading--h1">
          <strong style={{ fontSize: '1.4em' }}>ETCH</strong>
          <br /> Made-to-Measure Suits from just £299
        </h1>

        <div>
          <h2 className="heading heading--h3">
            Find your perfect fit with an ETCH suit
          </h2>
          <Button href="/contact">Book a consultation</Button>
        </div>
      </div>

      <TopHighlights>
        <div>Over 120 fabrics</div>
        <div>100% made-to-measure</div>
      </TopHighlights>
      <HowItWorksSection>
        <div className="container compact">
          <h3 className="heading heading--h3">How it works</h3>
          <p>
            Each ETCH suit is individually made to order,{' '}
            <strong>uniquely for you.</strong>
          </p>
          <p>
            With enhanced fit compared to off-the-peg suits, plus styling to
            perfectly match the occasion and your personality, our
            made-to-measure suit service delivers personal tailoring with
            outstanding quality at amazing prices.
          </p>
        </div>
      </HowItWorksSection>

      <DesigningYourSuitSection>
        <div className="container">
          <h3 className="heading heading--h3 text-center">
            Designing your suit
          </h3>
          {/* <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eveniet,
            iure voluptatum. Aspernatur vitae nihil, ea quasi voluptatibus, ab
            temporibus nesciunt, sequi voluptatum aperiam molestiae? Omnis nam
            accusantium consequuntur fugit beatae!
          </p> */}
          <Blocks>
            <li>
              <img className="icon" src={clothIcon} alt="" />
              <h4 className="heading heading--h4">Cloth Selection</h4>
              <p>
                Choose from over 120 cloths, from crease-resistant and stretch
                blends to pure wool Italian and English thoroughbreds.
              </p>
            </li>
            <li>
              <img className="icon" src={jacketIcon} alt="" />
              <h4 className="heading heading--h4">Fit & Styling</h4>
              <p>
                Our experienced tailor will guide you through the entire
                process, determining the perfect fit, cut and styling of your
                suit.
              </p>
            </li>
            <li>
              <img className="icon" src={buttonsIcon} alt="" />
              <h4 className="heading heading--h4">Detailing</h4>
              <p>
                Choose from a wide range of linings, buttonhole threads, piping
                and buttons. Embroider a name, monogram or date.
              </p>
            </li>
          </Blocks>
        </div>
      </DesigningYourSuitSection>
      <CtaRow>
        <h3 className="heading heading--h3">
          Ready to experience made-to-measure?
        </h3>
        <div className="buttonGroup">
          <a className="button" href="/">
            Book a showroom appointment
          </a>
          <a className="button" href="/">
            Arrange a home or office visit
          </a>
        </div>
      </CtaRow>
      {/* <div className="heading heading--h3">
        From consultation to final fitting
      </div> */}
      <WeddingSuitsSection>
        <div className="container">
          <h2 className="heading heading--h2">
            Made-to-measure
            <br />
            wedding suit service
          </h2>

          <Blocks>
            <li>
              <img className="icon" src={phoneIcon} alt="" />
              <h4 className="heading heading--h4">Introduction</h4>
              <p>
                15-minute introductory phone call to understand your
                requirements.
              </p>
            </li>
            <li>
              <img className="icon" src={meetingIcon} alt="" />
              <h4 className="heading heading--h4">Consultation</h4>
              <p>
                45-minute consultation at a convenient time and place to design
                a suit befitting your special day.
              </p>
            </li>
            <li>
              <img className="icon" src={tapeMeasureIcon} alt="" />
              <h4 className="heading heading--h4">Measuring</h4>
              <p>
                1-2 hour fitting with your groomsmen depending on the size of
                your wedding party.
              </p>
            </li>
            <li>
              <img className="icon" src={dummyIcon} alt="" />
              <h4 className="heading heading--h4">Fitting</h4>
              <p>20-40 minute fitting to ensure all suits fit as expected.</p>
            </li>
          </Blocks>
        </div>
      </WeddingSuitsSection>
      <BusinessSuitsSection>
        <div className="container">
          <h2 className="heading heading--h2">
            Made-to-measure suits for business and special occasions
          </h2>
          <Blocks>
            <li>
              <img className="icon" src={phoneIcon} alt="" />
              <h4 className="heading heading--h4">Introduction</h4>
              <p>
                15-minute introductory phone call to understand your
                requirements.
              </p>
            </li>
            <li>
              <img className="icon" src={meetingIcon} alt="" />
              <h4 className="heading heading--h4">
                Consultation & Measurement
              </h4>
              <p>
                45-minute consultation to design your suit and take measurements
              </p>
            </li>
            <li>
              <img className="icon" src={dummyIcon} alt="" />
              <h4 className="heading heading--h4">Fitting</h4>
              <p>20-minute fitting to ensure the fit is as expected.</p>
            </li>
          </Blocks>
        </div>
      </BusinessSuitsSection>
      <aside className="container compact">
        <h2 className="heading heading--h2">
          Suit inspiration at your finger tips
        </h2>
        <p>Find more inspiration and prices from our ETCH look book</p>
        <a href="/">ETCH look book</a>
      </aside>
      <div>
        <cite>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Officiis
            eveniet molestias magnam facilis optio. Voluptatem quae neque
            eveniet minima vitae, doloribus magni quibusdam, beatae saepe earum
            qui accusamus hic iure!
          </p>
        </cite>
      </div>
      <div>
        <p>Ready to find out more about our ETCH suits?</p>
        <Button href="/">Book your consultation today!</Button>
      </div>
      <footer>
        *Delivery of our ETCH suits can take around 8-10 from initial
        consultation. For wedding couples, we recommend to see you early in your
        wedding journey, start the process 12 weeks if possible, prior to your
        big day
      </footer>
      <div style={{ height: 30 }} />
      <EtchVideo />
    </PageBody>
    {/* <CollarDetailBanner /> */}
  </Layout>
)

export default EtchPage

// export const query = graphql`
//   {
//     hero: file(relativePath: { eq: "img/etch-suits-half.jpg" }) {
//       childImageSharp {
//         fluid(maxHeight: 500, quality: 70) {
//           ...GatsbyImageSharpFluid_withWebp
//         }
//       }
//     }
//   }
// `

const TopHighlights = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background: ${colors.lightGrey};
  font-weight: 500;
`

const HowItWorksSection = styled.section`
  background: ${colors.darkGrey};
  padding-top: 60px;
  padding-bottom: 70px;
  color: ${colors.white};

  .heading {
    margin-top: 0;
  }
`

const DesigningYourSuitSection = styled.section`
  padding-top: 60px;
  padding-bottom: 70px;

  min-height: 400px;

  background-image: url(${decorationsSrc});
  background-position: 30% 30%;
  background-size: auto 140%;
  background-repeat: no-repeat;

  > .container > .heading {
    margin-bottom: 40px;
  }
`

const WeddingSuitsSection = styled.section`
  padding-top: 60px;
  padding-bottom: 70px;

  .container > .heading {
    max-width: 450px;
    margin: 0 auto 30px;
    text-align: center;
    font-size: 2.1em;
  }

  ul {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }
`

const BusinessSuitsSection = styled.section`
  background: ${colors.lightGrey};
  padding-top: 60px;
  padding-bottom: 70px;

  .container > .heading {
    max-width: 450px;
    margin: 0 auto 30px;
    text-align: center;
    font-size: 2em;
  }
`

const Blocks = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 320px));
  justify-content: center;
  gap: 40px 25px;
  list-style: none;

  margin: 0;
  text-align: center;

  > *::after {
    content: '';
    display: block;
    width: 80px;
    height: 2px;
    background: ${colors.accent};
    margin: 10px auto 0;
  }

  .icon {
    height: 72px;
    margin: 0 auto;
  }

  .heading {
    font-size: 2rem;
    margin-bottom: 0.5em;
    color: ${colors.darkGrey};
    display: inline-block;
  }

  p {
    font-size: 1.7rem;
    margin-top: 0;
    min-height: 5em;
  }
`

const Button = styled.a`
  ${button.default}
  background: ${colors.accent};
  border-style: solid;
  border-color: ${colors.accent};
  color: #fff;
`

const CtaRow = styled.aside`
  background: ${colors.accent};
  text-align: center;
  padding: 50px 20px 70px;

  .buttonGroup {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-flow: row wrap;
    margin-top: 20px;
  }

  .button {
    ${button.default};
    ${button.outline};
    background: rgba(255, 255, 255, 0.05);
    white-space: nowrap;
    border-color: ${colors.primary};
    flex: 0 1 300px;
    font-weight: 500;
    transition: background 0.2s;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
`

export const Head = () => <meta name="robots" content="noindex" />

import React from 'react'
import { container } from '../styles/theme'
import styled from '@emotion/styled'
import FixedAspectBox from './fixed-aspect-box'

const EtchVideo = () => {
  return (
    <Container>
      <FixedAspectBox relativeWidth={16} relativeHeight={9}>
        <iframe
          title="ETCH Suits — Available from Fox Tailoring"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/wybe-Amo8zM?rel=0"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </FixedAspectBox>
    </Container>
  )
}

const Container = styled.div`
  ${container}
`

export default EtchVideo

import React from 'react'
import styled from '@emotion/styled'

const FixedAspectBox = ({
  aspectRatio,
  relativeWidth,
  relativeHeight,
  children,
  style,
}) => {
  const calculatedAr = parseInt(aspectRatio) || relativeWidth / relativeHeight
  return (
    <FixedAspectWrapper aspectRatio={calculatedAr} style={style}>
      <div className="inner">{children}</div>
    </FixedAspectWrapper>
  )
}

const FixedAspectWrapper = styled.div`
  & {
    height: 0;
    padding-top: ${(p) => (1 / p.aspectRatio) * 100}%;
    position: relative;
    > .inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

export default FixedAspectBox
